'use client';

import { useState, useRef, useEffect } from 'react';

/**
 * TypeWriter component props
 */
type TypeWriterProps = {
  strings: string[];
  charInterval?: number;
  stringInterval?: number;
};
/**
 * TypeWriter component
 * @param {TypeWriterProps} props
 * @returns JSX.Element
 */
export function FixedTypeWriter({
  strings,
  charInterval = 250,
  stringInterval = 500,
}: TypeWriterProps): JSX.Element {
  const [currentStringIndex, setCurrentStringIndex] = useState<number>(0);
  const [currentText, setCurrentText] = useState<string>('');
  const [charIndex, setCharIndex] = useState<number>(0);
  const [minWidth, setMinWidth] = useState<string>('auto');
  const hiddenTextRef = useRef<HTMLSpanElement | null>(null);

  // Calculate and set width based on the current full string
  useEffect(() => {
    if (hiddenTextRef.current && strings[currentStringIndex]) {
      hiddenTextRef.current.textContent = strings[currentStringIndex];
      setMinWidth(`${hiddenTextRef.current.scrollWidth}px`);
    }
  }, [currentStringIndex, strings]);

  useEffect(() => {
    const currentString = strings[currentStringIndex];

    if (currentString && charIndex < currentString?.length) {
      const timer = setTimeout(() => {
        setCurrentText(currentText + currentString[charIndex]);
        setCharIndex(charIndex + 1);
      }, charInterval);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setCurrentStringIndex((currentStringIndex + 1) % strings.length);
        if (strings[currentStringIndex] && strings[currentStringIndex][0]) {
          setCurrentText(strings[currentStringIndex][0]);
        }
        setCharIndex(1);
      }, stringInterval);

      return () => clearTimeout(timer);
    }
  }, [charIndex, currentStringIndex, charInterval, stringInterval]);

  return (
    <>
      {/* Hidden element to calculate width */}
      <span
        ref={hiddenTextRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
        }}
      />
      <span style={{ display: 'inline-block', minWidth, whiteSpace: 'nowrap' }}>
        {currentText || <>&nbsp;</>}
      </span>
    </>
  );
}
