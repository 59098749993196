'use client';

import { Button } from '@/components/ui/button';
import { useEffect, useRef, useState } from 'react';
import { Loader2 } from 'lucide-react';

/**
 * AnimatedButton component props
 */
type AnimatedButtonProps = {
  children: React.ReactNode;
  onClick?: () => any;
  shouldReset?: boolean;
  [key: string]: any;
};

/**
 * AnimatedButton component
 * @param {AnimatedButtonProps} props
 * @returns JSX.Element
 */
export default function AnimatedButton({
  children,
  onClick,
  shouldReset = true,
  ...props
}: AnimatedButtonProps): JSX.Element {
  const [clicked, setClicked] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null); // Create a ref for the button element
  const [buttonWidth, setButtonWidth] = useState<number | null>(null); // State to store the button's width

  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth); // Store the initial width of the button
    }
  }, [children]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!clicked) {
      setClicked(true);
      if (onClick) {
        const asyncOnClick = async () => {
          await onClick();
          if (shouldReset) setClicked(false);
        };
        asyncOnClick();
      }
    }
  };

  return (
    <Button
      ref={buttonRef}
      style={{ width: clicked && buttonWidth ? `${buttonWidth}px` : 'auto' }}
      onClick={handleClick}
      {...props}
    >
      {clicked ? <Loader2 className="animate-spin w-4 h-4" /> : children}
    </Button>
  );
}
