'use client';

import { Button } from '@/components/ui/button';
import { T, Plural, Num, Branch } from 'gt-next';
import { useState } from 'react';

export default function PluralClient({ count, setCount }: any) {
  return (
    <div>
      <T
        id="plural_card"
        context="Translate exactly the same, just with members or member depending on the number given."
      >
        <p>
          <Plural
            n={count}
            singular={
              <>
                Your team has <Num>{count}</Num> member.
              </>
            }
          >
            Your team has <Num>{count}</Num> members.
          </Plural>
        </p>
        <div className="pt-4 flex gap-2 select-none">
          <Button
            variant="secondary"
            onClick={() => {
              setCount((prev: number) => prev + 1);
            }}
          >
            Add
          </Button>
          <Branch
            branch={`${count > 0}`}
            true={
              <Button
                variant="secondary"
                onClick={() => {
                  setCount((prev: number) => prev - 1);
                }}
              >
                Subtract
              </Button>
            }
          />
        </div>
      </T>
    </div>
  );
}
