import CodeBlock from '@/components/gt-ui/CodeBlock';

export default function HelloWorldWithT() {
  return (
    <CodeBlock language="javascript">
      {`import { T } from 'gt-next'

export default function Page() {
  return (
    <T id="greeting">
      <p>Hello, world!</p>
    </T>
  )
}`}
    </CodeBlock>
  );
}
