export function capitalize(str: string) {
  if (!str) return '';
  return str[0]?.toUpperCase() + str.slice(1);
}

export const supportedLanguages = [
  'en', // English - United States
  'es', // Spanish - Spain
  'de', // German - Germany
  'fr', // French - France
  'ja', // Japanese - Japan
  'zh', // Chinese - China
  'ko', // Korean - South Korea
  'el', // Greek - Greece
  'ru', // Russian - Russia
  'sw-KE', // Swahili - Kenya
  'pt', // Portuguese - Portugal
  'af', // Afrikaans - South Africa
  'hi', // Hindi - India
  'he', // Hebrew - Israel
  'vi', // Vietnamese - Vietnam
  'it', // Italian - Italy
  'nl', // Dutch - Netherlands
  'tr', // Turkish - Turkey
  'pl', // Polish - Poland
  'fa', // Persian - Iran
  'th', // Thai - Thailand
  'id', // Indonesian - Indonesia
  'ms', // Malay - Malaysia
  'sv', // Swedish - Sweden
  'no', // Norwegian - Norway
  'fi', // Finnish - Finland
  'da', // Danish - Denmark
  'cs', // Czech - Czech Republic
  'hu', // Hungarian - Hungary
  'ro', // Romanian - Romania
  'sk', // Slovak - Slovakia
  'uk', // Ukrainian - Ukraine
];
