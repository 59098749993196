'use client';

import createGlobe from 'cobe';
import { useEffect, useRef } from 'react';
import { T } from 'gt-next';
import { useSpring } from 'react-spring';
import { useTheme } from 'next-themes';
import GetStartedButton from '@/components/landing/GetStartedButton';
import { MoveRight } from 'lucide-react';
// https://github.com/shuding/cobe

interface PointerPosition {
  x: number;
  y: number;
}

export default function SmallGlobe() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const globeRef = useRef<any>(null);
  const { theme } = useTheme();

  // Add these refs for drag interaction
  const pointerInteracting = useRef<PointerPosition | null>(null);
  const pointerInteractionMovementX = useRef(0);
  const pointerInteractionMovementY = useRef(0);

  // Add spring state for both rotations
  const [{ r, t }, api] = useSpring(() => ({
    r: 0, // horizontal rotation
    t: 0, // vertical rotation
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));

  useEffect(() => {
    let phi = 0;
    const container = containerRef.current;
    if (!container) return;

    const createGlobeInstance = () => {
      if (globeRef.current) {
        globeRef.current.destroy();
      }

      const globeSize = container.clientWidth;
      const mapSamples = window.innerWidth < 768 ? 8000 : 32000;

      globeRef.current = createGlobe(canvasRef.current as HTMLCanvasElement, {
        devicePixelRatio: 2,
        width: globeSize * 2,
        height: globeSize * 2,
        phi: 0,
        theta: 0,
        dark: 1,
        diffuse: 1.2,
        mapSamples,
        mapBrightness: window.innerWidth < 768 ? 4 : 6,
        baseColor: [0.3, 0.3, 0.3],
        markerColor: [0.1, 0.8, 1],
        glowColor: theme === 'dark' ? [0.2, 0.2, 0.2] : [1, 1, 1],
        markers: [
          // { location: [37.7595, -122.4367], size: 0.05 }, // San Francisco, USA
          // { location: [39.0438, -77.4874], size: 0.05 }, // Ashburn, North Virginia, USA
          // { location: [35.6762, 139.6503], size: 0.05 }, // Tokyo, Japan
          // { location: [50.1109, 8.6821], size: 0.05 }, // Frankfurt, Germany
          // { location: [51.5074, -0.1278], size: 0.05 }, // London, UK
          // { location: [19.076, 72.8777], size: 0.05 }, // Mumbai, India
          // { location: [-33.8688, 151.2093], size: 0.05 }, // Sydney, Australia
          // { location: [48.8566, 2.3522], size: 0.05 }, // Paris, France
          // { location: [-23.5505, -46.6333], size: 0.05 }, // São Paulo, Brazil
          // { location: [-1.2921, 36.8219], size: 0.05 }, // Nairobi, Kenya
          // { location: [-26.2041, 28.0473], size: 0.05 }, // Johannesburg, South Africa
        ],
        onRender: (state) => {
          if (!pointerInteracting.current) {
            phi -= 0.002;
          }
          state.phi = phi + r.get();
          state.theta = t.get();
        },
      });
    };

    createGlobeInstance();

    // Fade in the globe
    if (canvasRef.current) {
      canvasRef.current.style.opacity = '0';
      setTimeout(() => {
        if (canvasRef.current) canvasRef.current.style.opacity = '1';
      });
    }

    const resizeObserver = new ResizeObserver(() => {
      // Wrap the call in requestAnimationFrame to prevent the loop
      window.requestAnimationFrame(() => {
        createGlobeInstance();
      });
    });

    resizeObserver.observe(container);

    return () => {
      if (globeRef.current) {
        globeRef.current.destroy();
      }
      resizeObserver.disconnect();
    };
  }, [r, t, theme]);

  return (
    <div className="py-10 md:pb-24 flex flex-row justify-center items-center relative w-full">
      <div className="mx-auto w-full relative overflow-hidden">
        <T id="landing.copy.globe.title">
          <div className="flex flex-col gap-4 items-center max-w-[90vw] mx-auto">
            <h2
              className={`max-lg:text-center mt-2 text-3xl sm:text-5xl flex gap-4 items-center justify-center flourish-text font-semibold pb-4 hero-text`}
              style={{ textWrap: 'pretty' }}
            >
              Lightning-fast translation CDN
            </h2>
            <p
              className={
                'text-xl text-center mx-auto pt-2 flex flex-col gap-2 w-[90vw] max-w-[800px] md:text-xl/relaxed lg:text-md/relaxed xl:text-xl/relaxed text-gray-500 dark:text-gray-400 pb-5'
              }
              style={{ textWrap: 'pretty' }}
            >
              We run global infrastructure so your translations are as fast in
              Paris as they are in San Francisco
            </p>
            <GetStartedButton>
              Go to Dashboard <MoveRight />
            </GetStartedButton>
            <br />
          </div>
        </T>
        <div
          ref={containerRef}
          className="w-[60vw] md:w-[80vw] max-w-[600px] aspect-square mx-auto relative"
        >
          <canvas
            ref={canvasRef}
            className="w-full h-full aspect-square cursor-grab transition-opacity duration-1000"
            onPointerDown={(e) => {
              pointerInteracting.current = {
                x: e.clientX - pointerInteractionMovementX.current,
                y: e.clientY - pointerInteractionMovementY.current,
              };
              if (canvasRef.current)
                canvasRef.current.style.cursor = 'grabbing';
            }}
            onPointerUp={() => {
              pointerInteracting.current = null;
              if (canvasRef.current) canvasRef.current.style.cursor = 'grab';
            }}
            onPointerOut={() => {
              pointerInteracting.current = null;
              if (canvasRef.current) canvasRef.current.style.cursor = 'grab';
            }}
            onMouseMove={(e) => {
              if (pointerInteracting.current !== null) {
                const deltaX = e.clientX - pointerInteracting.current.x;
                const deltaY = e.clientY - pointerInteracting.current.y;
                pointerInteractionMovementX.current = deltaX;
                pointerInteractionMovementY.current = deltaY;
                api.start({
                  r: deltaX / 200,
                  t: deltaY / 200,
                });
              }
            }}
            onTouchMove={(e) => {
              if (pointerInteracting.current !== null && e.touches[0]) {
                const deltaX =
                  e.touches[0].clientX - pointerInteracting.current.x;
                const deltaY =
                  e.touches[0].clientY - pointerInteracting.current.y;
                pointerInteractionMovementX.current = deltaX;
                pointerInteractionMovementY.current = deltaY;
                api.start({
                  r: deltaX / 100,
                  t: deltaY / 100,
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
