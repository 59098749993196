'use client';

import { useRef, useState, useEffect } from 'react';

export default function Spotlight() {
  const spotlightRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!spotlightRef.current) return;
      const rect = spotlightRef.current.getBoundingClientRect();
      setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (!spotlightRef.current) return;
      const rect = spotlightRef.current.getBoundingClientRect();
      const touch = e.touches[0];
      if (!touch) return;
      setPosition({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      });
    };

    const handleMouseEnter = () => {
      setOpacity(1);
    };

    const handleMouseLeave = () => {
      setOpacity(0);
    };

    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('mousemove', handleMouseMove);
    document.body.addEventListener('mouseenter', handleMouseEnter);
    document.body.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('mousemove', handleMouseMove);
      document.body.removeEventListener('mouseenter', handleMouseEnter);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div
      ref={spotlightRef}
      className="fixed inset-0 w-full min-h-screen overflow-hidden -z-10"
    >
      <div className="absolute inset-0 pt-20">
        <div className="absolute inset-0 bg-gradient-to-b" />
        <div
          className="absolute inset-0 dark:hidden"
          style={{
            maskImage: `url('/mobileGlobe.svg')`,
            maskSize: '125%',
            maskPosition: 'center 20%',
            maskRepeat: 'no-repeat',
            background: `radial-gradient(
              min(50vw, 600px) circle at ${position.x}px ${position.y}px,
              rgba(0, 0, 0, 0.45),
              transparent 60%
            )`,
          }}
        />
        <div
          className="absolute inset-0 hidden dark:block"
          style={{
            maskImage: `url('/mobileGlobe.svg')`,
            maskSize: '125%',
            maskPosition: 'center 20%',
            maskRepeat: 'no-repeat',
            background: `radial-gradient(
              min(50vw, 600px) circle at ${position.x}px ${position.y}px,
              rgba(255, 255, 255, 0.35),
              transparent 60%
            )`,
          }}
        />
        <div
          className="absolute inset-0 bg-[url('/optimized-globe.svg')] bg-center bg-no-repeat brightness-0 dark:invert opacity-0"
          style={{
            backgroundSize: '125%',
            backgroundPosition: 'center 20%',
          }}
        />
      </div>
    </div>
  );
}
