'use client';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';
import { T, Plural, Num, Branch, Currency } from 'gt-next';
import { useDict, useGT } from 'gt-next/client';
import { useState } from 'react';

export default function CurrencyClient({
  cost,
  setCost,
  currencyCode,
  setCurrencyCode,
}: any) {
  const gt = useGT();
  return (
    <div>
      <T id="currency_card">
        <p>
          This product costs <Currency currency={currencyCode}>{cost}</Currency>
          .
        </p>
        <div className="py-4 flex gap-2 select-none">
          <Input
            type="number"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
          />
          <Select onValueChange={setCurrencyCode} defaultValue="USD">
            <SelectTrigger>
              <SelectValue placeholder={gt('Select a currency')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="USD">USD</SelectItem>
              <SelectItem value="EUR">EUR</SelectItem>
              <SelectItem value="JPY">JPY</SelectItem>
              <SelectItem value="GBP">GBP</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </T>
    </div>
  );
}
