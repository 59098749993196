'use client';

import { getLocaleProperties } from 'generaltranslation';
import { capitalize } from '@/lib/supportedLanguages';
import { useMemo, useState } from 'react';
import { listSupportedLocales } from '@generaltranslation/supported-locales';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Search } from 'lucide-react';
import { useLocale } from 'gt-next/client';

const simpleSearch = (term: string, keywords: string[]) => {
  for (const keyword of keywords) {
    if (keyword.toLowerCase().includes(term.toLowerCase())) return true;
  }
  return false;
};

export function LanguageGrid() {
  const supportedLocales = listSupportedLocales();
  const [searchTerm, setSearchTerm] = useState('');

  const userLocale = useLocale();

  const filteredLocales = useMemo(() => {
    const emojisToNames = new Map<string, string[]>();
    // filter codes with the same flag
    const finalLocales = supportedLocales.filter((code) => {
      const { languageCode, emoji } = getLocaleProperties(code, userLocale);
      const associatedCodes = emojisToNames.get(emoji);
      if (associatedCodes?.includes(languageCode)) return false;
      emojisToNames.set(emoji, [...(associatedCodes || []), languageCode]);
      return true;
    });
    if (!searchTerm) return finalLocales;
    return finalLocales.filter((code) => {
      const { name, nativeName, regionName, nativeRegionName } =
        getLocaleProperties(code, userLocale);
      return simpleSearch(searchTerm.trim(), [
        code,
        name,
        nativeName,
        regionName,
        nativeRegionName,
      ]);
    });
  }, [supportedLocales, searchTerm, userLocale]);

  const LanguageBlock = ({ code = 'en' }) => {
    const { emoji, languageName } = getLocaleProperties(code, userLocale);
    return (
      <div className="bg-background w-full object-contain filter font-md border rounded-lg p-1 hover:border-gray-400 transition-colors">
        <a href={`/${code}/welcome`}>
          <div className="flex gap-1.5 md:gap-2 justify-center items-center">
            <div className="text-lg md:text-xl">{emoji}</div>
            <div
              className="text-base md:text-lg font-medium"
              suppressHydrationWarning
            >
              {capitalize(languageName)}
            </div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className="relative z-20 py-8 px-4 md:px-8 max-w-[100vw]" dir={'ltr'}>
      <div className="max-w-6xl mx-auto">
        <div className="mb-4 px-8 flex justify-center">
          <div className="relative w-full max-w-sm">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder=""
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          </div>
        </div>
        <ScrollArea className="h-[600px] w-full rounded-md p-2 md:p-8 scroll-smooth">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-6">
            {filteredLocales.map((code, index) => (
              <LanguageBlock code={code} key={index} />
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
}
