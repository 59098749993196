//
// code / output tabs

import HelloWorldWithT from '@/components/landing/library/showcases/t/HelloWorldWithT';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { T } from 'gt-next';
import { Code, Code2, Languages } from 'lucide-react';

export const TranslatedTabs = () => {
  return (
    <T id="translated_tabs">
      <TabsList>
        <TabsTrigger value="code">Code</TabsTrigger>
        <TabsTrigger value="output">Output</TabsTrigger>
      </TabsList>
    </T>
  );
};

export default function TComponentShowcase() {
  return (
    <>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto flex items-center max-lg:mb-4">
        <T id="landing.library.showcases.t">
          <div className="grid md:grid-cols-[48px_2px_1fr] grid-cols-[1fr] items-center gap-x-6">
            <div className="hidden md:flex justify-center">
              <Code2 className="w-10 h-10" />
            </div>
            <div className="hidden md:block h-14 border-l-2 border-border" />
            <div>
              <h3 className="text-lg lg:text-xl font-semibold mb-4 bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent">
                Translate JSX
              </h3>
              <p className="text-base lg:text-lg leading-relaxed text-muted-foreground">
                Any UI passed as the children of the <b>&lt;T&gt;</b> component
                is tagged and translated.
              </p>
            </div>
          </div>
        </T>
      </div>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto">
        <Tabs defaultValue="output">
          <TranslatedTabs />
          <TabsContent value="code">
            <HelloWorldWithT />
          </TabsContent>
          <TabsContent value="output">
            <Card>
              <br />
              <CardContent>
                <T id="hello_world">
                  <p>Hello, world!</p>
                </T>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
