'use client';

import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getLocaleDirection,
  getLocaleName,
  getLocaleProperties,
  isSameLanguage,
} from 'generaltranslation';
import Link from 'next/link';

export default function ClientLanguageWriter({
  locale = 'en',
}: {
  locale: string;
}) {
  const languages = useMemo(() => {
    const localeProperties = getLocaleProperties(locale);
    const priorityLanguages = [
      localeProperties.languageCode,
      ...['en', 'es', 'fr', 'de', 'zh', 'ja', 'pt', 'it', 'ko'].filter(
        (code) => !isSameLanguage(code, localeProperties.languageCode)
      ),
    ];
    return priorityLanguages;
  }, [locale]);

  const [currentText, setCurrentText] = useState<string>(
    getLocaleName(languages[0] as string, locale)
  );
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const typingSpeed = 150;
  const hiddenTextRef = useRef<HTMLSpanElement | null>(null);
  const [minWidth, setMinWidth] = useState<string | number>('auto');

  useEffect(() => {
    const currentIndex = loopNum % languages.length;
    const fullText = getLocaleName(languages[currentIndex] as string, locale);

    const delta = isDeleting ? typingSpeed / 2 : typingSpeed;

    const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (!isDeleting) {
        // Typing phase
        setCurrentText(fullText.substring(0, currentText.length + 1));
        if (currentText === fullText) {
          // Pause before deleting
          setTimeout(() => setIsDeleting(true), 1000);
        }
      } else {
        // Deleting phase
        setCurrentText(currentText.substring(0, currentText.length - 1));
        if (currentText === '') {
          // Move to next language
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
        }
      }
    }, delta);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, loopNum, languages, typingSpeed]);

  // Calculate and set minimum width based on the longest language name
  useEffect(() => {
    if (hiddenTextRef.current) {
      setMinWidth(hiddenTextRef.current.scrollWidth);
    }
  }, [hiddenTextRef.current, languages, loopNum]);

  const currentCode = languages[loopNum % languages.length] as string;
  const currentFullText = getLocaleName(currentCode, locale);
  return (
    <>
      {/* Hidden element to calculate width */}
      <span
        ref={hiddenTextRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          left: 0,
          fontWeight: '500',
          whiteSpace: 'nowrap',
        }}
        suppressHydrationWarning
      >
        {currentFullText}
      </span>
      <Link
        className={`flourish-text font-medium ${getLocaleDirection(locale) !== 'rtl' ? 'text-left' : 'text-right'}`}
        href={`/${currentCode}`}
        style={{ minWidth, display: 'inline-block', whiteSpace: 'nowrap' }}
        suppressHydrationWarning
      >
        {currentText || <>&nbsp;</>}
      </Link>
    </>
  );
}
