'use client';

//
// code / output tabs

import CurrencyClient from '@/components/landing/library/showcases/currency/CurrencyClient';
import { CurrencyCode } from '@/components/landing/library/showcases/currency/CurrencyCode';
import { TranslatedTabs } from '@/components/landing/library/showcases/t/TComponentShowcase';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { T } from 'gt-next';
import { Calendar } from 'lucide-react';
import { useState } from 'react';

export default function FormattingShowcase() {
  const [cost, setCost] = useState(20);
  const [currencyCode, setCurrencyCode] = useState('USD');
  const childProps = { cost, setCost, currencyCode, setCurrencyCode };

  return (
    <>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto flex items-center max-lg:mb-4">
        <T id="landing.library.showcases.formatting">
          <div className="grid md:grid-cols-[48px_2px_1fr] grid-cols-[1fr] items-center gap-x-6">
            <div className="hidden md:flex justify-center">
              <Calendar className="w-10 h-10" />
            </div>
            <div className="hidden md:block h-14 border-l-2 border-border" />
            <div>
              <h3 className="text-lg lg:text-xl font-semibold mb-4 bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent">
                Format numbers, dates, and currencies
              </h3>
              <p className="text-base lg:text-lg leading-relaxed text-muted-foreground">
                The <b>&lt;Num&gt;</b>, <b>&lt;Currency&gt;</b>, and{' '}
                <b>&lt;DateTime&gt;</b> components automatically format their
                contents to your user's locale.
              </p>
            </div>
          </div>
        </T>
      </div>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto">
        <Tabs defaultValue="output">
          <TranslatedTabs />
          <TabsContent value="code">
            <CurrencyCode {...childProps} />
          </TabsContent>
          <TabsContent value="output">
            <Card>
              <br />
              <CardContent>
                <CurrencyClient {...childProps} />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
