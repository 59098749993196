'use client';

//
// code / output tabs

import PluralClient from '@/components/landing/library/showcases/plurals/PluralClient';
import { PluralCode } from '@/components/landing/library/showcases/plurals/PluralCode';
import HelloWorldWithT from '@/components/landing/library/showcases/t/HelloWorldWithT';
import { TranslatedTabs } from '@/components/landing/library/showcases/t/TComponentShowcase';
import { Card, CardContent, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { T } from 'gt-next';
import { BookCopy } from 'lucide-react';
import { useState } from 'react';

export default function PluralShowcase() {
  const [count, setCount] = useState(2);
  const childProps = { count, setCount };

  return (
    <>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto max-lg:mb-4 flex items-center min-h-full">
        <T id="landing.library.showcases.plurals">
          <div className="grid md:grid-cols-[48px_2px_1fr] grid-cols-[1fr] items-center gap-x-6">
            <div className="hidden md:flex justify-center">
              <BookCopy className="w-10 h-10" />
            </div>
            <div className="hidden md:block h-14 border-l-2 border-border" />
            <div>
              <h3 className="text-lg lg:text-xl font-semibold mb-4 bg-gradient-to-r from-primary to-primary/80 bg-clip-text text-transparent">
                Generate plural forms across languages
              </h3>
              <p className="text-base lg:text-lg leading-relaxed text-muted-foreground">
                Alternate plural forms in languages like Arabic and Polish are
                covered out of the box, with no extra engineering work needed.
              </p>
            </div>
          </div>
        </T>
      </div>
      <div className="lg:w-[48%] max-w-[600px] lg:mx-auto">
        <Tabs defaultValue="output">
          <TranslatedTabs />
          <TabsContent value="code">
            <PluralCode {...childProps} />
          </TabsContent>
          <TabsContent value="output">
            <Card>
              <br />
              <CardContent>
                <PluralClient {...childProps} />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
