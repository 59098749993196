'use client';

import { useRouter } from 'next/navigation';
import AnimatedButton from '../animated/AnimatedButton';

export default function GetStartedButton({ children }: { children: any }) {
  const router = useRouter();
  return (
    <AnimatedButton
      className="text-md flourish-background"
      shouldReset={false}
      onClick={() => router.push('/dashboard')}
    >
      {children}
    </AnimatedButton>
  );
}
