import CodeBlock from '@/components/gt-ui/CodeBlock';

export function CurrencyCode({ cost, currencyCode }: any) {
  return (
    <CodeBlock language="javascript">
      {`import { T, Currency } from 'gt-next'

export function CurrencyExample() {

  const cost = ${cost};
  const currency = "${currencyCode}";

  return (
    <T id="currency_example">
      <p>
        This product costs {' '}
        <Currency currency={currency}>
          {cost}
        </Currency>.
      </p>
    </T>
  )
}`}
    </CodeBlock>
  );
}
