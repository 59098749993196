'use client';

import { Check, CopyIcon } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  stackoverflowLight,
  hybrid,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const light = stackoverflowLight;
const lightRgbString = 'rgb(246, 246, 246)';
const dark = hybrid;
const darkRgbString = 'rgb(29, 31, 33)';

type CodeBlockProps = {
  language?: string;
  children: string | React.ReactNode;
  className?: string;
};

// Helper function to convert React children to string
const childrenToString = (children: React.ReactNode): string => {
  if (typeof children === 'string') return children;
  if (Array.isArray(children)) return children.join('');
  return String(children);
};

export function CodeSnippet({
  language = 'javascript',
  children = 'function HelloWorld() { console.log("Hello") }',
  className = '',
}: CodeBlockProps) {
  const { resolvedTheme } = useTheme();

  const [hasMounted, setHasMounted] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const style = resolvedTheme === 'dark' ? dark : light;

  const codeString = childrenToString(children);

  const handleCopy = () => {
    navigator.clipboard.writeText(codeString);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const iconSize = 20;

  const CopyButton = ({ backgroundFill = true }) => {
    return (
      <button
        onClick={handleCopy}
        className="absolute top-1.5 right-2 rounded p-1 hover:bg-gray-100 dark:hover:bg-gray-800"
        aria-label="Copy code"
        style={{
          ...(backgroundFill && {
            background:
              resolvedTheme === 'dark' ? darkRgbString : lightRgbString,
          }),
        }}
      >
        {copied ? <Check size={iconSize} /> : <CopyIcon size={iconSize} />}
      </button>
    );
  };

  return (
    <div className={`relative group border rounded ${className}`} dir={'ltr'}>
      <div className="flex items-start">
        <div className="flex-1 overflow-x-auto [&:not(:hover)]:scrollbar-hide">
          {!hasMounted ? (
            <pre className="p-[0.5em] whitespace-pre block">{codeString}</pre>
          ) : (
            <SyntaxHighlighter
              className="whitespace-pre"
              language={language}
              style={style}
            >
              {codeString}
            </SyntaxHighlighter>
          )}
        </div>
        <div className="flex items-center justify-center border-l min-w-[48px] self-stretch">
          <button
            onClick={handleCopy}
            className="rounded"
            aria-label="Copy code"
          >
            {copied ? <Check size={iconSize} /> : <CopyIcon size={iconSize} />}
          </button>
        </div>
      </div>
    </div>
  );
}

/**
 * CodeBlock component that displays code with syntax highlighting and copy functionality.
 * @param {string} language - The programming language of the code.
 * @param {string} content - The code content to display.
 * @returns {JSX.Element} - The rendered CodeBlock component.
 */
export default function CodeBlock({
  language = 'javascript',
  children = 'function HelloWorld() { console.log("Hello") }',
  className = '',
}: CodeBlockProps) {
  const { resolvedTheme } = useTheme();

  const [hasMounted, setHasMounted] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const style = resolvedTheme === 'dark' ? dark : light;

  const codeString = childrenToString(children);

  const handleCopy = () => {
    navigator.clipboard.writeText(codeString);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const copyBarClassName =
    'flex rounded-t justify-between items-center text-md px-[0.75em] pt-[0.5em] pb-[1em]';
  const iconSize = 16;

  const CopyButton = () => {
    return (
      <button
        onClick={handleCopy}
        className="rounded focus:outline-none"
        aria-label="Copy code"
      >
        {copied ? <Check size={iconSize} /> : <CopyIcon size={iconSize} />}
      </button>
    );
  };

  return (
    <div
      className={`relative group border border-0 rounded ${className}`}
      dir={'ltr'}
    >
      {!hasMounted ? (
        <>
          <div className={copyBarClassName}>
            <div>{language}</div>
            <CopyButton />
          </div>
          <pre className="p-[0.5em] whitespace-pre block overflow-x-auto">
            {codeString}
          </pre>
        </>
      ) : (
        <>
          <div
            style={{
              background:
                resolvedTheme === 'dark' ? darkRgbString : lightRgbString,
            }}
            className={copyBarClassName}
          >
            <div>{language}</div>
            <CopyButton />
          </div>
          <SyntaxHighlighter
            className="whitespace-pre block rounded-b overflow-x-auto"
            language={language}
            style={style}
          >
            {codeString}
          </SyntaxHighlighter>
        </>
      )}
    </div>
  );
}
