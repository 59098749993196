'use client';

import CodeBlock from '../gt-ui/CodeBlock';

export default function EnvExample({
  projectId = '[YOUR PROJECT ID]',
  apiKey = '[YOUR API KEY]',
}) {
  return (
    <div className="w-full">
      <CodeBlock language=".env">
        {`GT_API_KEY="${apiKey}"\n` + `GT_PROJECT_ID="${projectId}"`}
      </CodeBlock>
    </div>
  );
}
