'use client';

import { getLocaleProperties } from 'generaltranslation';
import Link from 'next/link';
import Marquee from 'react-fast-marquee';
import { capitalize, supportedLanguages } from '@/lib/supportedLanguages';
import { useMemo } from 'react';

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function LanguageCloudMarquee() {
  const languagesA = useMemo(
    () => shuffle(supportedLanguages),
    [supportedLanguages]
  );
  const languagesB = useMemo(
    () => shuffle([...supportedLanguages]),
    [supportedLanguages]
  );

  const LanguageBlock = ({ code = 'en' }) => {
    const { emoji, nativeLanguageName } = getLocaleProperties(code, code);
    return (
      <div className="md:w-40 w-32 object-contain filter mx-4 md:mx-10 font-md">
        <Link href={`/${code}`}>
          <div className="flex gap-2 md:gap-3 justify-center items-center">
            <div className="text-xl md:text-[1.75em]">{emoji}</div>
            <div
              className="text-lg md:text-xl font-medium"
              suppressHydrationWarning
            >
              {capitalize(nativeLanguageName)}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div
      className="relative z-20 py-10 md:py-32 px-4 md:px-8 max-w-[100vw]"
      dir={'ltr'}
    >
      <div className="flex gap-10 flex-wrap justify-center  relative h-full w-full mt-20 max-w-7xl mx-auto [mask-image:linear-gradient(to_right,transparent,black_20%,black_80%,transparent)]">
        <Marquee direction="right">
          {languagesA.map((code, index) => (
            <LanguageBlock code={code} key={index} />
          ))}
        </Marquee>
      </div>
      <div className="flex gap-10 flex-wrap justify-center md:gap-40 relative h-full w-full mt-8 md:mt-20 max-w-7xl mx-auto [mask-image:linear-gradient(to_right,transparent,black_20%,black_80%,transparent)]">
        <Marquee direction="left">
          {languagesB.map((code, index) => (
            <LanguageBlock code={code} key={index} />
          ))}
        </Marquee>
      </div>
    </div>
  );
}
