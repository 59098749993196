'use client';

import CodeBlock from '@/components/gt-ui/CodeBlock';

export function PluralCode({ count }: { count: number }) {
  return (
    <CodeBlock language="javascript">
      {`import { T, Plural, Num } from 'gt-next'

export default function Page() {

  const count = ${count};

  return (
    <T id="plural_example">
      <Plural 
        n={count}
        singular={
          <>
            Your team has <Num>{count}</Num> member.
          </>
        }
      >
        Your team has <Num>{count}</Num> members.
      </Plural>
    </T>
  )
}`}
    </CodeBlock>
  );
}
